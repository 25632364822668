import { Box, Stack, Button } from "@mui/material";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FormButton } from "suregifts-component-lib";
import { useGetRegistry } from "../../../services/registry";

function FailedVerificationView() {
  const { urlPath } = useParams();
  const naviate = useNavigate();
  return (
    <div style={{ display: "flex", justifyContent: "center", minHeight: "100vh", alignItems: "center" }}>
      <Box
        sx={{
          maxWidth: "400px",

          textAlign: "center",
          "& .title": {
            fontWeight: 600,
            fontSize: "2rem",
            mt: "24px",
          },
          "& .description": {
            fontWeight: 500,
            my: "1rem",
            lineHeight: "20px",
            fontSize: "1rem",
            color: "#8C8C9D",
          },
        }}
      >
        <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="alert-triangle">
            <path
              id="Vector"
              d="M34.8001 12.8673L6.56674 60.0007C5.98463 61.0088 5.67663 62.1517 5.67337 63.3157C5.67011 64.4798 5.97171 65.6244 6.54816 66.6358C7.12461 67.6471 7.95582 68.4898 8.9591 69.0802C9.96237 69.6705 11.1027 69.9879 12.2667 70.0007H68.7334C69.8974 69.9879 71.0378 69.6705 72.041 69.0802C73.0443 68.4898 73.8755 67.6471 74.452 66.6358C75.0284 65.6244 75.33 64.4798 75.3268 63.3157C75.3235 62.1517 75.0155 61.0088 74.4334 60.0007L46.2001 12.8673C45.6058 11.8877 44.7691 11.0777 43.7707 10.5156C42.7723 9.95351 41.6459 9.6582 40.5001 9.6582C39.3543 9.6582 38.2278 9.95351 37.2294 10.5156C36.231 11.0777 35.3943 11.8877 34.8001 12.8673Z"
              stroke="#EC1A3C"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path id="Vector_2" d="M40.5 30V43.3333" stroke="#EC1A3C" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            <path id="Vector_3" d="M40.5 56.666H40.5333" stroke="#EC1A3C" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
          </g>
        </svg>

        <div className="title">Payment Failed</div>
        <div className="description">Your donation was unsccessful</div>
        <Box sx={{ marginTop: "2rem", display: "grid", gap: "1rem", justifyContent: "center" }}>
          <FormButton
            label="Try again"
            style={{ width: "320px" }}
            onClick={() => {
              naviate(`/registry/${urlPath}/donate`);
            }}
          />
          <Link style={{ color: "white", textDecoration: "none" }} to={`/registry/${urlPath}`}>
            Done
          </Link>
        </Box>
      </Box>
    </div>
  );
}

export default FailedVerificationView;
