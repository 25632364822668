import { useParams, useSearchParams } from "react-router-dom";
import { WalletType, getPaymentType } from "suregifts-component-lib";

export function useRetrievePaymentData() {
  const [searchParams] = useSearchParams();
  const { type } = useParams();

  const paymentMethod = getPaymentType(type);
  console.log(paymentMethod);

  let invoiceNumber: any = "";
  let providerRef: any = "";
  switch (paymentMethod) {
    case WalletType.Paypal:
      providerRef = searchParams.get("token");
      break;
    case WalletType.Paystack:
      invoiceNumber = searchParams.get("trxref");
      break;
    case WalletType.Crypto:
      invoiceNumber = searchParams.get("token");
      break;
    case WalletType.FlutterwaveKenya:
      invoiceNumber = searchParams.get("tx_ref");
      providerRef = searchParams.get("transaction_id");
      break;
    case WalletType.Kora:
      invoiceNumber = searchParams.get("reference");
      break;
    default:
      break;
  }
  return { invoiceNumber, providerRef, paymentMethod };
}
