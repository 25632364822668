import { Container, Grid } from "@mui/material";
import { Outlet, useParams } from "react-router-dom";
import { useGetRegistry } from "../../services/registry";
import { useDocumentTitle } from "suregifts-component-lib";

import ContributorsView from "./ContributorsView";
import { RegistryMainView } from "./RegistryMainView";
import { RegistryInfoView } from "./RegistryInfoView";

function RegistryScreen() {
  const { urlPath } = useParams();
  const { data } = useGetRegistry(urlPath);
  useDocumentTitle(data?.name ?? "Registry");

  if (!data) return null;
  return (
    <Container sx={{ marginY: "40px" }}>
      <Grid container spacing={"1rem"}>
        <Grid item lg={8}>
          <RegistryMainView data={data} />
        </Grid>
        <Grid item lg={4}>
          <RegistryInfoView data={data} />
          {data.showContributors && <ContributorsView />}
        </Grid>
      </Grid>
      <Outlet />
    </Container>
  );
}

export default RegistryScreen;
