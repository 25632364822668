import { Box } from "@mui/material";

export const ProgressBar = ({ percentCompleted }) => {
  if (typeof percentCompleted === "undefined") return null;
  return (
    <Box
      sx={{
        backgroundColor: "#F2F2F2",
        width: "100%",
        height: "10px",
        marginY: "1rem",
        borderRadius: "30px",
        my: "24px",
        "& > div": {
          height: "100%",
          borderRadius: "30px",
          bgcolor: "error.main",
          width: `${percentCompleted}%`,
        },
      }}
    >
      <div></div>
    </Box>
  );
};
