import { Divider, Box } from "@mui/material";
import { RegistryDto } from "../../services/registry";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";

export type RegistryViewProps = {
  data: RegistryDto;
};

export const RegistryMainView = ({ data }: RegistryViewProps) => {
  return (
    <>
      <img
        src="/assets/images/registry.png"
        style={{
          width: "100%",
          minHeight: "380px",
          maxHeight: "380px",
          objectFit: "cover",
          borderRadius: "16px",
        }}
        alt=""
      />
      <h1 style={{ fontSize: "32px", fontWeight: 600 }}>{data.name}</h1>
      <p style={{ fontSize: "14px", fontWeight: 500, lineHeight: "20px", color: "#8C8C9D" }}>{data.message}</p>
      <Divider sx={{ marginY: "32px" }} />
      <div style={{ display: "flex", gap: "0.5rem" }}>
        <Box
          component={"span"}
          sx={{
            padding: "16px 16px",
            backgroundColor: "primary.main",
            border: "1px solid #25242E",
            borderRadius: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ShareIcon style={{ color: "#b2b2b2" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "90px",
            px: "16px",
            backgroundColor: "primary.main",
            borderRadius: "0.5rem",
            border: "1px solid #25242E",
            fontSize: "14px",
          }}
        >
          {data?.fullUrlPath}
          <button style={{ background: "none", border: "none", cursor: "pointer" }}>
            <ContentCopyIcon sx={{ color: "#8C8C9D" }} />
          </button>
        </Box>
      </div>
    </>
  );
};
