import { Box, Divider } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { RegistryDto, useGetContributors } from "../../services/registry";
import { formatMoney } from "suregifts-component-lib";
import { useParams } from "react-router-dom";

function ContributorsView() {
  const { urlPath } = useParams();
  const { data: contributors } = useGetContributors(urlPath as string);
  if (!contributors || contributors.length == 0) return null;
  console.log(contributors);

  return (
    <Box boxShadow={1} sx={{ padding: "24px", marginTop: "1rem", borderRadius: "16px", backgroundColor: "primary.main" }}>
      <h2 style={{ margin: 0, fontSize: "20px", fontWeight: 700 }}>Recent Contributors</h2>
      <Divider sx={{ my: "1rem" }} />
      <div style={{ display: "grid", gap: "1rem", maxHeight: "500px", overflow: "scroll" }}>
        {contributors?.map((item) => (
          <Box style={{ display: "flex", fontSize: "14px", fontWeight: "500", alignItems: "center", gap: "8px" }}>
            <Box
              sx={{
                width: "34px",
                bgcolor: "#F2F2F2",
                height: "34px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
              }}
            >
              <FavoriteBorderIcon sx={{ width: 16, height: 16, color: "#B2B2B2" }} />
            </Box>
            <div style={{ display: "flex", justifyContent: "space-between", flexGrow: 1, fontSize: "14px" }}>
              <span style={{ fontWeight: 600 }}>{item.fullName}</span>
              <span style={{ color: "#858585" }}>{formatMoney(item.amount, item.currency)}</span>
            </div>
          </Box>
        ))}
      </div>
    </Box>
  );
}

export default ContributorsView;
