import { Box, Button } from "@mui/material";
import { dateOnly, formatMoney } from "suregifts-component-lib";
import { TargetIcon, DonateHandIcon } from "./Icons";
import { RegistryViewProps } from "./RegistryMainView";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PeopleIcon from "@mui/icons-material/People";

import { ProgressBar } from "./ProgressBar";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

export const RegistryInfoView = ({ data }: RegistryViewProps) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ boxShadow: "1", padding: "24px", borderRadius: "16px", backgroundColor: "primary.main" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
        <div>
          <div style={{ fontSize: "20px", lineHeight: "25px", fontWeight: 700 }}>{formatMoney(data.totalRaised, data.currency)} raised</div>
          {data.targetAmount && (
            <div
              style={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#6F6F6F",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginTop: "5px",
                marginBottom: "1rem",
              }}
            >
              <TargetIcon /> Target:
              {formatMoney(data.targetAmount, data.currency)}
            </div>
          )}
        </div>
        <div style={{ border: "1px solid #DDDDDD", padding: "8px", borderRadius: "8px" }}>
          <DonateHandIcon />
        </div>
      </div>
      <ProgressBar percentCompleted={data.percentCompleted} />
      <div style={{ display: "grid", gap: "8px" }}>
        <RegistryAttributeView icon={<CalendarTodayIcon color="error" sx={{ width: 16, height: 16 }} />} label={`Ends ${dateOnly(data.endDate)}`} />
        <RegistryAttributeView label={`${data.contributors} Contributions`} icon={<PeopleIcon color="error" sx={{ width: 16, height: 16 }} />} />
      </div>

      <Button
        onClick={() => navigate("donate")}
        color="error"
        sx={{ padding: "16px", fontWeight: 700, fontSize: "15px", marginTop: "30px", display: "block", borderRadius: "50vh" }}
        fullWidth
      >
        Donate
      </Button>
    </Box>
  );
};

type RegistryAttributeViewProps = {
  icon: ReactNode;
  label: string;
};
const RegistryAttributeView = ({ label, icon }: RegistryAttributeViewProps) => {
  return (
    <Box style={{ display: "flex", fontSize: "14px", fontWeight: "500", alignItems: "center", gap: "8px" }}>
      <Box
        sx={{
          width: "34px",
          bgcolor: "primary.light",
          height: "34px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
        }}
      >
        {icon}
      </Box>
      <span>{label}</span>
    </Box>
  );
};
