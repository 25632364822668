import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { formatMoney, FormButton, LoadingBox, TransactionStatusType } from "suregifts-component-lib";
import { useRetrievePaymentData } from "../../../hooks/useRetrievePaymentData";
import { VerifyPaymentResult, useVerify } from "../../../services/transaction";
import { Box, Button, Stack } from "@mui/material";
import { useGetRegistry } from "../../../services/registry";
import VerifySuccessView from "./VerifySuccessView";
import FailedVerificationView from "./FailedVerificationView";

function DonationPaymentCallback() {
  const { invoiceNumber, paymentMethod, providerRef } = useRetrievePaymentData();
  const { mutate: verifyPayment, data } = useVerify();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname.indexOf("/payment_callback") === -1) {
      navigate("/");
      return;
    }

    if ((!invoiceNumber && !providerRef) || !paymentMethod) {
      navigate("/");
      return;
    }
    const paymentModel = { invoiceNumber, providerRef, paymentMethod };
    verifyPayment(paymentModel, {});
  }, []);

  if (!data) return <LoadingBox loading loadingPosition="center" />;
  if (data.statusId !== TransactionStatusType.Successful) return <VerifySuccessView amountPaid={data.amountPaid} />;
  return <FailedVerificationView />;
}

export default DonationPaymentCallback;
