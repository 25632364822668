import { Dialog, DialogContent, DialogActions, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DialogTitle, FormInput, FormButton, YupShape, formatMoney, WalletType, useAuthContext, getPaymentPath } from "suregifts-component-lib";
import { DonateModel, useGetRegistry, useRegistryDonate } from "../../services/registry";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
const schema = yup
  .object<YupShape<DonateModel>>({
    fullName: yup.string().required(),
    email: yup.string().email().required(),
    amount: yup.number().required(),
  })
  .required();
function DonateModal() {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("..");
  };
  const { urlPath } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm<DonateModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      isAnonymous: false,

      fullName: "Azeez",
      email: "a6ee6@yahooo.com",
      amount: 5000,
    },
  });
  const { mutate: submitData, isLoading } = useRegistryDonate(urlPath);
  const submitForm = (model: DonateModel) => {
    model.paymentMethodId = user?.countryId === 1 ? WalletType.Paystack : WalletType.FlutterwaveKenya;
    model.returnPath = `payment_callback/${getPaymentPath(model.paymentMethodId)}`;
    submitData(model, {
      onSuccess: (data) => {
        window.location.href = data.paymentLink;
      },
    });
  };

  const { data } = useGetRegistry(urlPath);
  const amount = watch("amount");
  return (
    <Dialog open maxWidth="xs" onClose={handleClose}>
      <DialogTitle title={`Donate to ${data?.name}`} handleClose={handleClose} />
      <DialogContent>
        <Stack spacing={2}>
          <FormInput label="Full name" {...register("fullName")} helperText={errors.fullName?.message} />
          <FormInput label="Email Address" {...register("email")} helperText={errors.email?.message} />
          <FormInput startAdornment={user?.currency} isNumber label="Amount" {...register("amount")} helperText={errors.amount?.message} />
          <Controller
            control={control}
            name="isAnonymous"
            render={({ field }) => (
              <FormControlLabel
                value="female"
                control={<Checkbox color="success" />}
                onChange={(e) => field.onChange((e.target as HTMLInputElement).checked)}
                label={"Contribute anonymously"}
              />
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormButton loading={isLoading} onClick={handleSubmit(submitForm)} fullWidth color="error" label={`Donate ${formatMoney(amount)}`} />
      </DialogActions>
    </Dialog>
  );
}

export default DonateModal;
