import { Box } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FormButton } from "suregifts-component-lib";
import { useGetRegistry } from "../../../services/registry";
type VerifyPageProps = {
  amountPaid: string;
};
function VerifySuccessView({ amountPaid }: VerifyPageProps) {
  const { urlPath } = useParams();
  const { data: registry } = useGetRegistry(urlPath);
  const naviate = useNavigate();
  return (
    <div style={{ display: "flex", justifyContent: "center", minHeight: "100vh", alignItems: "center" }}>
      <Box
        sx={{
          maxWidth: "400px",

          textAlign: "center",
          "& .title": {
            fontWeight: 600,
            fontSize: "2rem",
            mt: "24px",
          },
          "& .description": {
            fontWeight: 500,
            my: "1rem",
            lineHeight: "20px",
            fontSize: "1rem",
            color: "#8C8C9D",
          },
        }}
      >
        <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="check-circle">
            <path
              id="Vector"
              d="M73.8332 36.9342V40.0009C73.8291 47.1889 71.5015 54.1831 67.1976 59.9403C62.8937 65.6974 56.8441 69.9091 49.951 71.9472C43.0579 73.9853 35.6907 73.7405 28.9481 71.2495C22.2054 68.7584 16.4487 64.1545 12.5364 58.1244C8.62406 52.0943 6.76581 44.9611 7.23876 37.7886C7.71171 30.6161 10.4905 23.7886 15.1608 18.3244C19.831 12.8602 26.1425 9.05212 33.1538 7.468C40.1651 5.88388 47.5007 6.60864 54.0665 9.53419"
              stroke="#92FBB3"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Vector_2"
              d="M73.8333 13.334L40.5 46.7007L30.5 36.7007"
              stroke="#92FBB3"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
        <div className="title">
          Thank you!
          <br />
          Donation Successful
        </div>
        <div className="description">
          You’ve successfully donated {amountPaid} to {registry?.name}
        </div>
        <Box sx={{ marginTop: "2rem", display: "grid", gap: "1rem", justifyContent: "center" }}>
          <FormButton
            label="Donate Again"
            style={{ width: "320px" }}
            onClick={() => {
              naviate(`/registry/${registry?.urlPath}/donate`);
            }}
          />
          <Link style={{ color: "white", textDecoration: "none" }} to={`/registry/${registry?.urlPath}`}>
            Done
          </Link>
        </Box>
      </Box>
    </div>
  );
}

export default VerifySuccessView;
